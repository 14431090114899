<template>
    <results-layout>
        <v-container v-if="questionsMap && questionsMap.length > 0" class="question pb-12 px-8">
            <div class="my-8 text-h5">Liked Answers</div>

            <div v-for="(question, i) in questionsMap" :key="`liked-question-${i}`" class="mb-8">
                <div class="mb-6">
                    <span class="text-subtitle-1">
                        {{ (question.order + 1) + '. '  + (question.text||'') }}
                    </span>
                    <v-chip color="primary lighten-5" small>
                        <span class="text-subtitle-2" style="color: var(--primary)">{{ question.type }}</span>
                    </v-chip>
                </div>

                <template v-if="audioType.includes(question.type)">
                    <v-card elevation="0" class="rounded-0">
                        <v-row no-gutters class="py-4" style="background-color: white">
                            <v-col class="grey--text text-caption" style="min-width: 232px; max-width: 232px;">
                                <span class="pl-4">Audio</span>
                            </v-col>
                            <v-col class="grey--text text-caption" cols="6">Transcript</v-col>
                            <v-col class="grey--text text-caption">Sentiment</v-col>
                        </v-row>
                    </v-card>
                    <v-divider></v-divider>
                    <v-card class="rounded-0" elevation="0">
                        <audio-item v-for="(answer, j) in question.answers" :key="answer.id"
                            :answer="{...answer, position: j+1}" @playAudio="playAudio" @onLikeToggle="toggleLike"></audio-item>
                    </v-card>
                </template>
                <template v-else>
                    <v-card class="rounded-0" elevation="0">
                        <v-row no-gutters class="py-4" style="background-color: white">
                            <v-col class="grey--text text-caption" style="margin-left: 100px; max-width: 356px">Answer</v-col>
                            <v-col class="grey--text text-caption">Date Added</v-col>
                        </v-row>
                    </v-card>
                    <v-divider></v-divider>
                    <v-card class="rounded-0" elevation="0">
                        <regular-item v-for="(answer, j) in question.answers" :key="answer.id"
                            :answer="{...answer, position: j+1}" @onLikeToggle="toggleLike"/>
                    </v-card>
                </template>
            </div>
        </v-container>
        <v-container v-else>
            <img class="mt-16" src="https://cdn.voiceform.com/media/no-likes-illustration.svg" style="height: 300px" />
            <div class="mt-8 mb-6 text-h5 text-center">No liked answers, yet!</div>
            <div class="text-center">To like, select the ❤️ on your standout answers.</div>
        </v-container>

        <v-footer v-if="audioOnPlayer" color="primary lighten-5" app padless>
            <audio-player></audio-player>
        </v-footer>
    </results-layout>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { VOICE_QUESTION, VOICE_RESPONSE } from '../../../../configs/questionTypes';
import ResultsLayout from '../../../../layouts/ResultsLayout.vue';
import AudioItem from '../../../../components/answer/audio/AudioItem.vue';
import RegularItem from '../../../../components/answer/regular/RegularItem.vue';
import AudioPlayer from '../../../../components/AudioPlayer.vue'

import useSWRV from 'swrv';
import { useRoute } from 'vue-router/composables';
import fetcher from '@/api/fetcher';
import useSwrvState from '@/utils/useSwrvState'

export default {
    name: 'Likes',
    components: {
        ResultsLayout, AudioItem, RegularItem, AudioPlayer
    },
    data() {
        return {
            audioType: [VOICE_QUESTION, VOICE_RESPONSE]
        };
    },
    computed: {
        ...mapGetters({
            audioPlayer: 'survey/audioPlayer'
        }),
        audioOnPlayer() {
            return this.audioPlayer.currentAudioAnswer !== null;
        },
        likedAudioAnswers() {
            if(this.questionsMap){
                return this.questionsMap
                    .map(question => question.answers.filter(answer => answer.liked && answer.file_url))
                    .flat();
            }
            return []
        }
    },
    methods: {
        ...mapMutations({
            setAudioPlayback: 'survey/setAudioPlayback',
            removeAudioFromLikedList: 'survey/removeAudioFromLikedList'
        }),
        playAudio(answer) {
            this.setAudioPlayback({
                answer,
                answerList: this.likedAudioAnswers
            });
        },
        toggleLike(answer, value) {
            if (value === false) {
                if(answer.file_url){
                    this.removeAudioFromLikedList({
                        answerId: answer.id,
                        newAudioList: this.likedAudioAnswers.filter(ans => ans.id !== answer.id)
                    });
                }
            }
        }
    },
    setup(){
        const route = useRoute();
        const { data, error, isValidating, mutate } = useSWRV(
            () =>`/api/analytics/${route.params.surveyId}/liked-answers`,
            fetcher
        )
        const { state, STATES } = useSwrvState(data, error, isValidating)

        return {
            state, STATES,
            questionsMap: data,
            error,
            isValidating,
            revalidateQuestionsMap: mutate,
        }
    }
};
</script>
